import { FC } from 'react';
import { combineReducers } from 'redux';
import { mdModule } from '@next-common/breakpoint';
import { EconomicOperator as DrawerEconomicOperator } from '@next-common/legal-economic-operator';
import { renderReact } from '../../fragmentHelper/renderReactCustom';

import { ReduxApolloFragmentWrapperChildren } from '../../fragmentHelper/Wrapper';

const mdReducer = mdModule.reducer;

const EconomicOperator: FC = () => {
  const reducer = combineReducers({
    md: mdReducer,
  });
  return (
    <ReduxApolloFragmentWrapperChildren initialApolloState={{}} initialReduxState={{}} moduleName="ShippingMap" reducers={reducer}>
      <DrawerEconomicOperator />
    </ReduxApolloFragmentWrapperChildren>
  );
};

export const serverCanCache = true;

export const renderFunction = renderReact('EconomicOperator', EconomicOperator);
